<script lang="ts" setup>
    import type { ButtonProps } from '~/components/atm/Button.vue';
    import type { GA4SelectContentPayload } from '~/plugins/gtm.client';

    interface ProductCardButtonsProps {
        isDarkBackground?: boolean;
        addToCartDisabled?: boolean;
        product: CMSProduct;
        variant?: CMSProductVariant;
        cartBusy: boolean;
        addToCartClicked: boolean;
        handleAddToCart: () => void;
        pdpUrl?: string;
        size?: ButtonProps['size'];
        shortTranslation?: boolean;
        trackPdpClick?: GA4SelectContentPayload;
    }
    const props = withDefaults(defineProps<ProductCardButtonsProps>(), {
        size: 'small',
    });
    const nuxtApp = useNuxtApp();

    const handlePDPClick = () => {
        if (!props.trackPdpClick) return;
        nuxtApp.$gtm.selectContent(props.trackPdpClick);
    };
</script>
<template>
    <div class="flex w-full flex-wrap items-center gap-2 max-md:flex-col">
        <atm-button
            :styling="isDarkBackground ? 'solid-invert' : 'solid'"
            :disabled="addToCartDisabled || !canVariantQuickSell(product, variant?.id)"
            :loading="cartBusy && addToCartClicked"
            :size="size"
            @click="handleAddToCart">
            {{ shortTranslation ? $t('cta.cart.buy') : $t('cta.cart.add') }}
            <template #icon>
                <woom-icon-add-to-cart />
            </template>
        </atm-button>
        <lazy-atm-button
            v-if="pdpUrl"
            :to="pdpUrl"
            :styling="isDarkBackground ? 'outline-invert' : 'outline'"
            kind="link"
            :size="size"
            @click="handlePDPClick">
            {{ $t('cta.viewDetails') }}
        </lazy-atm-button>
    </div>
</template>
